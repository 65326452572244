<template>

  <div>

    <b-row>
      <b-col md="12">
        <b-card
          class="mb-0"
        >

          <b-row>
            <b-col md="12">
              <!-- BODY -->
              <validation-observer
                #default="{ handleSubmit }"
                ref="refFormObserver"
              >
                <!-- Form -->
                <b-form
                  v-if="notificationData"
                  class="p-2"
                  @submit.prevent="handleSubmit(saveData)"
                  @reset.prevent="resetForm"
                >

                  <b-form-group
                    v-if="usersData"
                    :label="$t('Bu Kullanıcıları Dahil Et')"
                    label-for="selected_users"
                  >
                    <b-row>
                      <b-col md="9">
                        <v-select
                          id="selected_users"
                          v-model="notificationData.selected_users"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          multiple
                          :options="usersData"
                          :reduce="val => val.value"
                          :clearable="false"
                          input-id="selected_users"
                        />
                      </b-col>
                      <b-col md="3">

                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-primary"
                          @click="selectAllUsers(true)"
                        >
                          <feather-icon
                            icon="HomeIcon"
                            class="mr-50"
                          />
                          <span class="align-middle">{{ $t('Tümünü Seç') }}</span>
                        </b-button>
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-primary"
                          @click="selectAllUsers(false)"
                        >
                          <feather-icon
                            icon="HomeIcon"
                            class="mr-50"
                          />
                          <span class="align-middle">{{ $t('Seçimleri Kaldır') }}</span>
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-form-group>
                  <b-form-group
                    v-if="rolesData"
                    :label="$t('Bu Rollerdeki Kullanıcıları Dahil Et')"
                    label-for="selected_roles"
                  >
                    <b-row>
                      <b-col md="9">
                        <v-select
                          id="selected_roles"
                          v-model="notificationData.selected_roles"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          multiple
                          :options="rolesData"
                          :reduce="val => val.value"
                          :clearable="false"
                          input-id="selected_roles"
                        />
                      </b-col>
                      <b-col md="3">

                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-primary"
                          @click="selectAllRoles(true)"
                        >
                          <feather-icon
                            icon="HomeIcon"
                            class="mr-50"
                          />
                          <span class="align-middle">{{ $t('Tümünü Seç') }}</span>
                        </b-button>
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-primary"
                          @click="selectAllRoles(false)"
                        >
                          <feather-icon
                            icon="HomeIcon"
                            class="mr-50"
                          />
                          <span class="align-middle">{{ $t('Seçimleri Kaldır') }}</span>
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-form-group>
                  <b-form-group
                    v-if="rolesData"
                    :label="$t('Bildirim Türü')"
                    label-for="m_type"
                  >

                    <v-select
                      id="m_warning_type"
                      v-model="notificationData.warning_type"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="$staticParams.notificationWarningTypes"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="m_warning_type"
                    >
                      <template #option="{ label, icon,color }">
                        <b-avatar
                          size="32"
                          :variant="color"
                        >
                          <feather-icon
                            :icon="icon"
                            :variant="color"
                            size="16"
                            class="align-middle mr-50"
                          />
                        </b-avatar>

                        <span> {{ label }}</span>
                      </template>
                      <template #selected-option="{ label, icon,color }">
                        <b-avatar
                          size="32"
                          :variant="color"
                        >
                          <feather-icon
                            :icon="icon"
                            :variant="color"
                            size="16"
                            class="align-middle mr-50"
                          />
                        </b-avatar>

                        <span> {{ label }}</span>
                      </template>
                    </v-select>

                  </b-form-group>
                  <b-row>
                    <b-col md="6">
                      <validation-provider
                        #default="validationContext"
                        :name="$t('Bildirim Başlığı')"
                        rules="required|min:2"
                      >
                        <b-form-group
                          :label="$t('Bildirim Başlığı')"
                          label-for="m_title"
                        >
                          <b-form-input
                            id="m_title"
                            v-model="notificationData.title"
                            autofocus
                            :state="getValidationState(validationContext)"
                            trim
                          />

                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col md="6">
                      <validation-provider
                        #default="validationContext"
                        :name="$t('Bildirim Mesajı')"
                        rules="required|min:2"
                      >
                        <b-form-group
                          :label="$t('Bildirim Mesajı')"
                          label-for="m_message"
                        >
                          <b-form-input
                            id="m_message"
                            v-model="notificationData.message"
                            autofocus
                            :state="getValidationState(validationContext)"
                            trim
                          />

                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>

                  <validation-provider
                    #default="validationContext"
                    :name="$t('Bildirim İçeriği')"
                    rules="required|min:2"
                  >
                    <b-form-group
                      :label="$t('Bildirim İçeriği')"
                      label-for="m_content"
                    >

                      <quill-editor
                        ref="description"
                        v-model="notificationData.content"
                        :options="snowOption"
                        :state="getValidationState(validationContext)"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <!-- Form Actions -->
                  <div class="d-flex mt-2">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mr-2"
                      type="submit"
                    >
                      {{ $t('Gönder') }}
                    </b-button>

                  </div>
                </b-form>

              </validation-observer>
            </b-col>
          </b-row>

        </b-card>
      </b-col>
    </b-row>

  </div>
</template>

<script>
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import {
  BCard, BRow, BCol, VBModal, BButton, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BAvatar,
} from 'bootstrap-vue'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import { quillEditor } from 'vue-quill-editor'
import vSelect from 'vue-select'
import notificationStoreModule from './notificationStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BFormInvalidFeedback,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BForm,
    vSelect,
    quillEditor,
    BFormGroup,
    BFormInput,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      required,
      showMarkAsReturnedModal: false,
      returnCompletionDate: '',
      showEditSidebar: false,
    }
  },
  methods: {
    emailListValidation(email) {
      const mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      if (email.match(mailformat)) {
        return true
      }

      return false
    },
    addDynamicParam(type = '') {
      const { quill } = this.$refs.description

      const length = quill.selection.savedRange.index
      if (type === 'name') {
        quill.insertText(length, '{{name}}')
      } else if (type === 'surname') {
        quill.insertText(length, '{{surname}}')
      } else if (type === 'company_name') {
        quill.insertText(length, '{{company_name}}')
      }
    },

    selectAllUsers(select = false) {
      this.notificationData.selected_users = []
      if (select) {
        this.usersData.forEach(val => {
          this.notificationData.selected_users.push(val.value)
        })
      }
    },
    selectAllRoles(select = false) {
      this.notificationData.selected_roles = []
      if (select) {
        this.rolesData.forEach(val => {
          this.notificationData.selected_roles.push(val.value)
        })
      }
    },
    openEditSidebarForEditing(item) {
      this.selectedBankAccount = JSON.parse(JSON.stringify(item))
      this.showEditSidebar = true
    },
    openEditSidebarForAdd() {
      this.showEditSidebar = true
      this.selectedBankAccount = JSON.parse(JSON.stringify(this.blankNotificationData))
    },
    // eslint-disable-next-line no-unused-vars
    async  saveData() {
      this.$swal({
        title: this.$t('Gönderim Onayı'),
        text: this.$t('Bildirim gönderimi tek yönlü bir işlemdir ve gönderildikten sonra işlem geri alınamaz.'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Gönderim İşlemini Onayla'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('app-notification/addNotification', this.notificationData)
          // eslint-disable-next-line no-unused-vars
            .then(response => {
              this.$refs.refFormObserver.reset()

              this.resetnotificationData()
              this.showSuccessMessage()
            })
            .catch(() => {
              this.showErrorMessage()
            })
        }
      })
    },

    showSuccessMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarılı'), {
        title: this.$t('İşlem Başarılı'),
        variant: 'success',
        solid: false,
      })
    },
    showErrorMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarısız'), {
        title: this.$t('İşlem Başarısız'),
        variant: 'danger',
        solid: false,
      })
    },
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-notification'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, notificationStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })
    const notificationData = ref([])
    const countryOptions = ref([null])
    const usersData = ref([null])
    const rolesData = ref([null])
    const emailCategoryData = ref([null])

    const blankNotificationData = {
      title: '',
      message: '',
      content: '',
      warning_type: 1,
      selected_users: [],
      selected_roles: [],

    }

    store
      .dispatch('app/fetchUsers', {
        list_type: 'all',
      })
      .then(response => {
        const { users } = response.data.data
        const arr = []
        users.forEach(val => {
          arr.push({ label: `${val.name} (${val.email})`, value: val.id })
        })

        usersData.value = arr
      })
      .catch(() => {
      })
    store
      .dispatch('app/fetchRoles', {
        list_type: 'all',
      })
      .then(response => {
        const { data } = response.data
        const arr = []
        data.forEach(val => {
          arr.push({ label: `${val.name}`, value: val.id })
        })

        rolesData.value = arr
      })
      .catch(() => {
      })
    const resetnotificationData = () => {
      notificationData.value = JSON.parse(JSON.stringify(blankNotificationData))
    }
    resetnotificationData()
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetnotificationData)
    return {
      notificationData,
      blankNotificationData,
      refFormObserver,
      getValidationState,
      countryOptions,
      resetForm,
      usersData,
      rolesData,
      emailCategoryData,
      resetnotificationData,
      snowOption: {
        theme: 'snow',
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style scoped>
.per-page-selector {
  width: 90px;
}
.list-group-item {
  transition: all 1s
}
#add-new-bank-account-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
